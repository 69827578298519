import { PanelData } from "@grafana/data";
import { AlarmStatus, GroupInfo, LastData, LinkInfo as LinkInfo, SimpleOptions, SvgParserResults, TagInfo, UnitInfo } from "types";

export function getAlarmColor(alarmStatus: AlarmStatus, options: SimpleOptions): string {
    if (alarmStatus.isAlarmHHActive) {
        return options.alarm_hh;
    } else if (alarmStatus.isAlarmHActive) {
        return options.alarm_h;
    } else if (alarmStatus.isAlarmLLActive) {
        return options.alarm_ll;
    } else if (alarmStatus.isAlarmLActive) {
        return options.alarm_l;
    }
    console.error("Error during alarm parsing. We should not get here.");
    return "";
}

export function checkUnitAlarms(value: number | undefined, unitInfo: UnitInfo | undefined): AlarmStatus {
    if (!value || !unitInfo) {
        return {
            isAlarmHActive: false,
            isAlarmHHActive: false,
            isAlarmLActive: false,
            isAlarmLLActive: false,
            isAnyAlarmActive: false
        };
    }

    const isAlarmHActive = unitInfo.AlarmH !== null && value > unitInfo.AlarmH;
    const isAlarmHHActive = unitInfo.AlarmHH !== null && value > unitInfo.AlarmHH;
    const isAlarmLActive = unitInfo.AlarmL !== null && value < unitInfo.AlarmL;
    const isAlarmLLActive = unitInfo.AlarmLL !== null && value < unitInfo.AlarmLL;
    return {
        isAlarmHActive,
        isAlarmHHActive,
        isAlarmLActive,
        isAlarmLLActive,
        isAnyAlarmActive: isAlarmHActive || isAlarmHHActive || isAlarmLActive || isAlarmLLActive
    };
}

export function svgParser(svg: string): SvgParserResults {
    let svg_copy = svg;

    // Add the missing namespace if it's not already present
    if (!svg_copy.includes('xmlns:xlink="http://www.w3.org/1999/xlink"')) {
        svg_copy = svg_copy.replace('<svg', '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
    }

    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(svg_copy, 'image/svg+xml');
    const viewBoxString = svgDoc.documentElement?.getAttribute("viewBox") ?? "";
    // Remove the filtered groups from the SVG document

    const extractGroupInfo = (group: Element): GroupInfo => {
        // Search for a nested group that contains specific attributes

        const destination = group.getAttribute("destination");

        return {
            group,
            destination,
        };
    };

    const tagGroups = Array.from(svgDoc.querySelectorAll('svg > g[key="tag_group"]'))
        .map(extractGroupInfo);

    const offpageGroups = Array.from(svgDoc.querySelectorAll('svg > g[key="offpage_group"]'))
        .map(extractGroupInfo);

    [...tagGroups, ...offpageGroups].forEach(({ group }) => group.parentNode?.removeChild(group));


    // Serialize the modified SVG back to a string
    const serializer = new XMLSerializer();
    const cleanedSvg = serializer.serializeToString(svgDoc);

    // Return both the groups and the cleaned SVG string
    return { tagGroups, offpageGroups, cleanedSvg, viewBoxString };
}

export function parseTaginfos(tagInfoString: string | undefined) {
    if (!tagInfoString) { return []; }
    if (tagInfoString !== "") {
        try {
            return JSON.parse(tagInfoString) as TagInfo[];
        }
        catch (e) {
            console.error("The string for the mapping information is not well formatted.", e);
        }
    }
    return [];
}

export function parseLinks(linkString: string | undefined) {
    if (!linkString) { return {}; }
    if (linkString !== "") {
        try {
            return JSON.parse(linkString) as LinkInfo;
        }
        catch (e) {
            console.error("The string for the mapping information is not well formatted.", e);
        }
    }
    return {};
}


export function filterLast(panelData: PanelData): LastData[] {
    return panelData.series.map(el => {
        return { tagname: el.name, data: el.fields[1].values[el.fields[1].values.length - 1] as number | null };
    });
}


export const parseStyleString = (styleString: string): React.CSSProperties => {
    const styleObject: React.CSSProperties = {};
    styleString.split(';').forEach((stylePair) => {
        const [key, value] = stylePair.split(':');
        if (key && value) {
            const formattedKey = key.trim().replace(/-./g, (match) => match.charAt(1).toUpperCase());
            //@ts-ignore
            styleObject[formattedKey] = value.trim();
        }
    });
    return styleObject;
};
