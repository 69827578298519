import { PanelPlugin } from '@grafana/data';
import { SimpleOptions } from './types';
import { UhdeSVGPanel } from './components/UhdeSVGPanel';

export const plugin = new PanelPlugin<SimpleOptions>(UhdeSVGPanel).setPanelOptions((builder) => {
  return builder
    .addTextInput({
      path: 'svg_data', name: "SVG Input box", description: "Paste your svg here.", defaultValue: "", settings: {
        useTextarea: true,
        rows: 5,
      }
    }).addTextInput({
      path: 'mapping', name: "Mapping Information", description: "Mapping between the svg tags and the signals", defaultValue: "", settings: {
        useTextarea: true,
        rows: 3,
      }
    }).addTextInput({
      path: 'links', name: "Links", description: "Links to other pages", defaultValue: "", settings: {
        useTextarea: true,
        rows: 3,
      }
    }).addColorPicker({
      path: 'alarm_h',
      name: 'High Alarm Color',
      description: 'Choose color for high alarm',
      defaultValue: 'red',
    })
    .addColorPicker({
      path: 'alarm_hh',
      name: 'Very High Alarm Color',
      description: 'Choose color for very high alarm',
      defaultValue: 'darkred',
    })
    .addColorPicker({
      path: 'alarm_l',
      name: 'Low Alarm Color',
      description: 'Choose color for low alarm',
      defaultValue: 'yellow',
    })
    .addColorPicker({
      path: 'alarm_ll',
      name: 'Very Low Alarm Color',
      description: 'Choose color for very low alarm',
      defaultValue: 'orange',
    });
});
