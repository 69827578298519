import React from "react";
import { AlarmStatus, LastData, RectProps, TagInfo, TextProps } from "types";
import { checkUnitAlarms, getAlarmColor, parseStyleString } from "./HelperFunctions";
import { useUhdeSVGContext } from "context/UhdeSVGContext";
import { Tooltip } from "@grafana/ui";

function SVGRect(props: RectProps) {
    return <rect {...props} />;
}

function SVGText(props: TextProps) {
    return <text {...props}>{props.children}</text>;
}

function tagTest(element: Element, LastValues: LastData[], TagInfos: TagInfo[]) {
    const isGroup = element.tagName.toLowerCase() === "g";
    const key = element.getAttribute("key");
    const taginfo = element.getAttribute("taginfo");
    const tagname = element.getAttribute("tagname");
    const isTagGroup = isGroup && key !== null && taginfo !== null && tagname !== null;
    let lastValue: number | undefined;
    let tagInfo: TagInfo | undefined;
    let alarmStatus: AlarmStatus | undefined;
    if (isTagGroup) {
        const compareString1 = `${tagname}.${taginfo}`.toLocaleLowerCase();
        const compareString2 = `${tagname}.${key}`.toLocaleLowerCase();

        tagInfo = TagInfos.find(taginfo => taginfo.fulltagname.toLowerCase() === compareString1 || tagInfo?.fulltagname.toLowerCase() === compareString2);
        lastValue = LastValues.find(lv => lv.tagname?.toLowerCase() === compareString1 || lv.tagname?.toLowerCase() === compareString2)?.data ?? undefined;
        alarmStatus = checkUnitAlarms(lastValue, tagInfo?.unitinfo);
    }
    return {
        isGroup,
        isTagGroup,
        lastValue,
        tagInfo,
        alarmStatus
    };

}

function TransformElement(props: { element: Element, tagInfo?: TagInfo | undefined, lastValue?: number | undefined, alarmStatus?: AlarmStatus | undefined; }) {
    const { LastValues, TagInfos } = useUhdeSVGContext();
    const { element, tagInfo, lastValue, alarmStatus } = props;
    const { PanelPropsState } = useUhdeSVGContext();
    const tagName = element.tagName.toLowerCase();
    const extractedProps = extractProps(element);




    const tagGroupInfo = tagTest(element, LastValues, TagInfos);
    let children: React.ReactNode[] = [];
    if (element.childNodes.length > 0) {
        if (tagGroupInfo.isTagGroup) {
            children = Array.from(element.childNodes)
                .filter((node) => node.nodeType === Node.ELEMENT_NODE)
                .map((node, index) => <TransformElement key={index} element={node as Element} tagInfo={tagGroupInfo.tagInfo} lastValue={tagGroupInfo.lastValue} alarmStatus={tagGroupInfo.alarmStatus} />);
        } else {
            children = Array.from(element.childNodes)
                .filter((node) => node.nodeType === Node.ELEMENT_NODE)
                .map((node, index) => <TransformElement key={index} element={node as Element} tagInfo={tagInfo} lastValue={lastValue} alarmStatus={alarmStatus} />);
        }
    }

    switch (tagName) {
        case 'rect':
            if (alarmStatus && alarmStatus.isAnyAlarmActive) {
                const prop = extractedProps as RectProps;
                const color = getAlarmColor(alarmStatus, PanelPropsState.options);
                return (<>
                    <rect {...extractedProps} style={{ ...prop.style, stroke: color, strokeWidth: 3 }}></rect>
                </>);
            }
            return <SVGRect {...extractedProps} />;
        case 'text':
            return <SVGText {...extractedProps}>{lastValue?.toFixed(3) ?? ""}</SVGText>;
        case 'g':
            return <ConditionalTooltipWrapper tagInfo={tagInfo}>
                <g {...extractedProps}>{children}</g>
            </ConditionalTooltipWrapper>;
        default:
            return React.createElement(tagName, extractedProps, children);
    }
};

function extractProps(element: Element) {
    const extractedProps: any = {};
    Array.from(element.attributes).forEach((attr) => {
        if (attr.name === 'style') {
            extractedProps.style = parseStyleString(attr.value);
        } else {
            extractedProps[attr.name] = attr.value;
        }
    });
    return extractedProps;
}

function TransformGroupToReactComponent(props: { group: Element, lastValue?: number | undefined, tagInfo?: TagInfo | undefined, alarmStatus?: AlarmStatus | undefined; link?: string | undefined; }) {
    const { group, link } = props;

    const stopPropagation = (event: React.MouseEvent) => {
        event.stopPropagation();
    };

    const onClick = link ? () => {
        window.location.href = link;
    } : undefined;

    const nonSelectableStyle: React.CSSProperties = {
        userSelect: 'none',
        MozUserSelect: 'none',
        WebkitUserSelect: 'none',
        msUserSelect: 'none'
    };
    const linkStyle: React.CSSProperties = {
        // your additional styles here
        cursor: "pointer",
        pointerEvents: "all"
    };

    const combinedStyle: React.CSSProperties = link
        ? { ...nonSelectableStyle, ...linkStyle }
        : nonSelectableStyle;
    return (
        <g style={combinedStyle} onMouseDown={stopPropagation} onMouseUp={stopPropagation} onClick={onClick} >
            <TransformElement element={group} />
        </g>
    );
};

function ConditionalTooltipWrapper(props: { children: React.ReactElement, tagInfo?: TagInfo; }) {
    const { tagInfo, children } = props;
    return tagInfo ? (
        <Tooltip content={<TooltipContent tagInfo={tagInfo} />}>
            {children}
        </Tooltip>
    ) : (
        children
    );
}

function TooltipContent({ tagInfo }: { tagInfo?: TagInfo; }) {
    if (!tagInfo) { return <></>; };
    const { Description, DescriptionAlt, Unit, AlarmH, AlarmHH, AlarmL, AlarmLL, UpperLimit, LowerLimit } = tagInfo.unitinfo;
    const anyAlarmDefined = AlarmH !== null || AlarmHH !== null || AlarmL !== null || AlarmLL !== null;
    const anyLimits = UpperLimit !== null || LowerLimit !== null;

    return (
        <div style={{ padding: '10px', maxWidth: '300px' }}>
            <h5>{tagInfo.fulltagname}</h5>
            <h5>{Description || DescriptionAlt}</h5>
            {Unit && <div><strong>Unit:</strong> {Unit}</div>}
            {anyLimits ?
                <>
                    <h6 style={{ margin: '10px 0 5px 0' }}>Limits:</h6>
                    <div style={{ marginLeft: '15px' }}>
                        {UpperLimit !== null && <div>Upper: {UpperLimit}</div>}
                        {LowerLimit !== null && <div>Lower: {LowerLimit}</div>}
                    </div>
                </>
                : null
            }
            {anyAlarmDefined ?
                <>
                    <h6 style={{ margin: '10px 0 5px 0' }}>Alarms:</h6>
                    <div style={{ marginLeft: '15px' }}>
                        {AlarmHH !== null && <div>HH: {AlarmHH}</div>}
                        {AlarmH !== null && <div>H: {AlarmH}</div>}
                        {AlarmL !== null && <div>L: {AlarmL}</div>}
                        {AlarmLL !== null && <div>LL: {AlarmLL}</div>}
                    </div>
                </>
                : null
            }
        </div>
    );

}

export function SVGRenderer() {
    const { Links, PanelPropsState, SvgData } = useUhdeSVGContext();
    console.log("Render");

    if (!SvgData) { return null; }
    return (
        <div style={{ position: 'relative', width: PanelPropsState.width, height: PanelPropsState.height }}>
            <svg
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                }
                }
                width={PanelPropsState.width}
                height={PanelPropsState.height}
                viewBox={SvgData.viewBoxString}

                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                dangerouslySetInnerHTML={{ __html: SvgData.cleanedSvg }}
            />
            <svg
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                }}
                viewBox={SvgData.viewBoxString}
                width={PanelPropsState.width}
                height={PanelPropsState.height}
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink">
                <g>
                    {SvgData.tagGroups.map((groupInfo, index) => {
                        return <React.Fragment key={index}>
                            <TransformGroupToReactComponent group={groupInfo.group} />
                        </React.Fragment>;
                    })}
                </g>
                <g>
                    {SvgData.offpageGroups.map((groupInfo, index) => {
                        let link: string | undefined = undefined;
                        const { destination } = groupInfo;
                        if (destination && destination in Links) {
                            link = `/d/${Links[destination]}`;
                        }

                        return <React.Fragment key={index}>
                            <TransformGroupToReactComponent group={groupInfo.group} link={link} />
                        </React.Fragment>;
                    })}
                </g>
            </svg>
        </div>
    );
};
