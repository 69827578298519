import { filterLast, parseLinks, parseTaginfos, svgParser } from "components/HelperFunctions";
import React, { createContext, useContext, useEffect, useState } from "react";
import { LastData, SvgParserResults, UhdeProps } from "types";

type ContextType = UhdeProps | undefined;

interface InitProps {
    initProps: ContextType;
}

const UhdeSVGContext = createContext<ContextType>(undefined);

const UhdeSVGContextProvider = (props: InitProps & Record<string, any>) => {

    return (
        <UhdeSVGContext.Provider value={props.initProps}>
            {props.children}
        </UhdeSVGContext.Provider>
    );
};

const useUhdeSVGContext = () => {
    const initProps = useContext(UhdeSVGContext);
    const [lastValues, setLastValues] = useState<LastData[]>([]);
    const [svgData, setSvgData] = useState<SvgParserResults>();
    const tagInfos = parseTaginfos(initProps?.options.mapping);
    const links = parseLinks(initProps?.options.links);


    useEffect(() => {
        if (initProps?.data.state === "Done") {
            const newLastValues = filterLast(initProps?.data);
            if (JSON.stringify(newLastValues) !== JSON.stringify(lastValues)) {
                setLastValues(filterLast(initProps?.data));
            }
        }
    }, [initProps, initProps?.data, initProps?.data?.state, tagInfos, lastValues]);

    useEffect(() => {
        if (initProps?.options.svg_data) {
            const newSvgData = svgParser(initProps.options.svg_data);
            if (JSON.stringify(newSvgData) !== JSON.stringify(svgData)) {
                setSvgData(newSvgData);
            }

        }
    }, [initProps?.options.svg_data, svgData]);


    if (initProps === undefined) {
        throw new Error("Failure to provide initial context");
    }


    return {
        PanelPropsState: initProps,
        TagInfos: tagInfos,
        Links: links,
        LastValues: lastValues,
        SvgData: svgData
    };
};

export {
    UhdeSVGContextProvider,
    UhdeSVGContext,
    useUhdeSVGContext
};
